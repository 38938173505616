/* eslint-disable react/jsx-child-element-spacing */
import React from 'react'
import * as S from './styles'
import { TypographyV2 as Typography } from '@taglivros/tag-components'

function TermsAndConditions( { purchaseActionText }: TermsAndConditions.Props ) {
  return (
    <S.Container>
      <Typography
        color="TAG1-700"
        variant="text-small-regular"
        as="p"
      >
        Ao clicar em {purchaseActionText.toUpperCase()}, confirmo que li e concordo com os&nbsp;
        <a
          href="https://clube.taglivros.com/termos-de-servico-novas-assinaturas/"
          target="_blank"
        >
          Termos de uso e Política de Privacidade e Segurança
        </a>.&nbsp;
      </Typography>
      <Typography
        color="TAG1-700"
        variant="text-small-regular"
        as="p"
      >
        Este site é protegido pelo reCAPTCHA e são aplicáveis a&nbsp;
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
        >
          Política de Privacidade
        </a>
        &nbsp;e os&nbsp;
        <a
          href="https://policies.google.com/terms"
          target="_blank"
        >
          Termos de Serviço do Google
        </a>.
      </Typography>
    </S.Container>
  )
}

namespace TermsAndConditions {
  export interface Props {
    purchaseActionText: string
  }
}

export { TermsAndConditions }
