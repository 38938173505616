import cardValidator from 'card-validator'
import { onlyNumbers } from './formatter'
import { mapIteration } from './array'

export function validateCPF( input: string ): boolean {
  input = input.replace( /\.|\/|-/g, '' )

  if ( ( /\D/ ).test( input ) ) {
    return false
  }

  const digits = mapIteration( input.length, i => Number( input[ i ] ) )

  if ( digits.every( digit => digit === digits[ 0 ] ) ) {
    return false
  }

  const controls = digits.splice( 9 )

  let sum: number
  let mod: number
  let result: number

  sum = digits.reduce( ( previous, current, index ) => previous + current * ( 10 - index ), 0 )
  mod = sum % 11
  result = mod < 2 ? 0 : 11 - mod

  if ( result !== controls[ 0 ] ) {
    return false
  }

  digits.push( result )

  sum = digits.reduce( ( previous, current, index ) => previous + current * ( 11 - index ), 0 )
  sum += result * 11
  mod = sum % 11
  result = mod < 2 ? 0 : 11 - mod

  if ( result !== controls[ 1 ] ) {
    return false
  }

  return true
}

export function validateName( input: string ): boolean {
  return ( /^[a-zà-ïñ-öù-ü'-]+,?\s[a-zà-ïñ-öù-ü'\- ]+$/gi ).test( input )
}

export function validateEmail( input: string ): boolean {
  return ( /^[^@ç\s]+@[^@ç\s]+\.[^@ç\s]+\s*$/iu ).test( input )
}

export function validateNumber( input: string ): boolean {
  return ( /^[0-9]+$/ ).test( input )
}

export function validateCEP( input: string ): boolean {
  return ( /^[0-9]{2}.[0-9]{3}-[0-9]{3}$/ ).test( input )
}

export function validateStreet( input: string ): boolean {
  return ( /^[a-zà-ïñ-öù-ü0-9.,-/()\s]+$/i ).test( input )
}

export function validateCardExpirationMonth( input: string ): boolean {
  return Number( input.substring( 0, 2 ) ) <= 12
}

export function validateExpiredCardDate( input: string ): boolean {
  const today = new Date()

  const month = Number( input.substring( 0, 2 ) )
  const year = Number( input.substring( 3 ) )

  const currentMonth = today.getMonth() + 1
  const currentYear = today.getFullYear() - 2000

  return year > currentYear || ( year === currentYear && month >= currentMonth )
}

export function validateCardNumber( input: string ): boolean {
  return cardValidator.number( input ).isValid
}

export function validateCardFlag( input: string ): boolean {
  const validFlags = [ 'visa', 'mastercard', 'american-express', 'elo', 'hipercard' ]
  const cardFlag = cardValidator.number( onlyNumbers( input ) ).card!.type

  return validFlags.includes( cardFlag )
}

export function validateBirthdate( input: string ): boolean {
  if ( !input ) {
    return false
  }

  const onlyNumbersDate = onlyNumbers( input )
  const day = Number( onlyNumbersDate.substring( 0, 2 ) )
  const month = Number( onlyNumbersDate.substring( 2, 4 ) )
  const year = Number( onlyNumbersDate.substring( 4 ) )
  const currentDay = new Date().getDate()
  const currentMonth = new Date().getMonth() + 1
  const currentYear = new Date().getFullYear()

  return onlyNumbersDate.length === 8 && month <= 12 && day <= 31 &&
        ( currentYear > year || ( currentYear === year && currentMonth > month ) ||
        ( currentYear === year && currentMonth === month && currentDay > day ) )
}
