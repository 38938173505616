/* eslint-disable complexity */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-statements */
/* eslint-disable max-lines */
import React, { useEffect } from 'react'
import Header from '@one-checkout/components/Header'
import { MOBILE_THRESHOLD, PAYMENT_METHODS } from '@one-checkout/constants'
import useMaxWidth from '@one-checkout/hooks/useMaxWidth'
import IdentificationSummary from '@one-checkout/components/IdentificationSummary'
import ProductDescription from '@one-checkout/components/ProductDescription'
import TotalSummary from '@one-checkout/components/TotalSummary'
import PaymentMethod from '@one-checkout/components/PaymentMethod'
import useStep from '@one-checkout/hooks/useStep'
import { Step, stepMap } from './setup'
import { IdentificationForm } from '@one-checkout/components/IdentificationForm'
import { checkFormValidity, checkGiftAddressValidity } from '@one-checkout/utils/checkFormValidity'
import { TermsAndConditions } from '../TermsAndConditions'
import { setIdentificationData } from '@one-checkout/actions/identification.action'
import { validationTexts } from '@checkout/assets/constants/validationTexts'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import { CardPayload } from '@one-checkout/services/payment.service'
import PromotionCoupon from '../PromotionCoupon'
import Conclusion from '../Conclusion'
import Advertising from '../Advertising'
import PixConfirmation from '../PixConfirmation'
import { applyCoupon, changeCouponCode } from '@one-checkout/actions/promotionCoupon.action'
import { PaymentErrorModal } from '../PaymentErrorModal'
import { useSelector } from 'react-redux'
import store from '@one-checkout/store'
import { setProduct } from '@one-checkout/actions/product.action'
import { setMayProceedStep, verifyAndRedirectToAda } from '@one-checkout/actions/page'
import { getInstallments, purchase, setIsPaying, setPaymentData, setPaymentError, setPaymentMethod, verifyPixPayment } from '@one-checkout/actions/payment.action'
import * as S from './styles'
import { SubscriptionErrorModal } from '../SubscriptionErrorModal'
import { verifySubscriptionStatusByCpf } from '@one-checkout/actions/subscription.action'
import { isMonthlyProduct } from '@one-checkout/services/product.service'
import { setEventData, addPaymentInfoEvent, beginCheckoutEvent, beginPaymentInfoEvent, giveMemberDiscountEvent, pixPaymentAttemptEvent, purchaseSubmitEvent, selectItemEvent, selectPaymentMethodEvent } from '@one-checkout/actions/event.action'
import GiftSummary from '../GiftSummary/GiftSummary'
import { ProductRedirectModal } from '../ProductRedirectModal'
import { MethodType } from '@shared/interfaces/payment/methodPayments'
import Product from '@shared/interfaces/Product'
import { IdentificationSummaryGiftAddress } from '../IdentificationSummaryGiftAddress/IdentificationSummaryGiftAddress'
import { ToggleGiftAddress } from '../ToggleGiftAddress/ToggleGiftAddress'
import { GiftAddressForm } from '../GiftAddressForm/GiftAddressForm'
import { setCollapseIdentificationForm } from '../../actions/step.action'

function ControllerView( props: Controller.Props ) {
  const { product, promotionCouponCode, verifyMaintenance } = props

  const store = useSelector( ( state: store.State ) => state )

  const isMobile = useMaxWidth( MOBILE_THRESHOLD )
  const step = useStep<Step>( stepMap )

  const displayIdentificationSummary = ( step.isConcluded( 'identification/address' ) && step.current !== 'identification/address' ) || ( store.identification.giftAddress.useGiftAddress && store.step.colapseIdentificationForm )
  const displayIdentificationGiftAddressSumary = step.isConcluded( 'identification/address' ) && step.current !== 'identification/address'
  const displayPaymentMethod = step.isConcluded( 'identification/address' )

  const paymentMethodDiscount = getPaymentMethodDiscount()
  const purchaseActionText = store.product?.isSubscription ? 'Assinar' : 'Comprar'

  useEffect( () => {
    setProduct( product )
    setEventData( {
      'productType': product.type,
      'productName': product.name,
      'planId': product.planId,
      'categoryId': product.categoryId,
      'productPrice': product.price,
    } )
  }, [ product ] )

  useEffect( () => {
    if ( store.payment.isPaying ) {
      return setMayProceedStep( false )
    }

    let mayProceedStep = checkFormValidity( store.identification, 'identification' )
    const isGiftAddressFormValid = checkGiftAddressValidity( store.identification.giftAddress )

    mayProceedStep = mayProceedStep && isGiftAddressFormValid

    if ( mayProceedStep && step.isConcluded( 'identification/address' ) ) {
      if ( !store.payment.method ) {
        mayProceedStep = false
      } else {
        mayProceedStep = checkFormValidity( store.payment, store.payment.method )
      }
    }

    setMayProceedStep( mayProceedStep )
  }, [ store.identification, store.payment, step.current, store.payment.isPaying ] )

  useEffect( () => {
    if ( step.current === 'payment' ) {
      step.proceed()
      setPaymentMethod( 'card' )
    }
  }, [ step.current ] )

  useEffect( () => {
    if ( store.product ) {
      beginCheckoutEvent()
      selectItemEvent()

      if ( promotionCouponCode ) {
        changeCouponCode( promotionCouponCode.toUpperCase() )
        applyCoupon()
      }
    }
  }, [ store.product ] )

  useEffect( () => {
    const totalDiscount = store.promotionCoupon.appliedValue.price + paymentMethodDiscount + getExtraDiscount()
    setEventData( { totalDiscount } )
  }, [ store.promotionCoupon.appliedValue.price, paymentMethodDiscount ] )

  function getPaymentMethodDiscount():number {
    const retrieveDiscount = ( methodDetailToDiscount: MethodType ) => {
      const discount = store.product?.paymentMethodsDetail.filter( methodDetail => methodDetail.methodType === methodDetailToDiscount )[ 0 ]
      const discountPercent = discount?.percentageDiscount ? discount?.percentageDiscount / 100 : 0
      return ( ( store.product!.price - store.promotionCoupon.appliedValue.price ) + ( store.shipping.price || 0 ) ) * discountPercent
    }

    if ( store.product && store.payment.method === PAYMENT_METHODS.PIX ) {
      return retrieveDiscount( MethodType.PIX )
    }
    if ( store.product && store.payment.method === PAYMENT_METHODS.CARD ) {
      return retrieveDiscount( MethodType.CREDIT_CARD )
    }

    return 0
  }

  function getExtraDiscount() {
    let extraDiscount = 0

    if ( store.product?.enableMemberDiscount ) {
      const memberDiscount = store.lead.status === 'active' && !store.product?.isSubscription ? store.product?.memberDiscount : 0
      if ( memberDiscount && memberDiscount !== 0 ) {
        giveMemberDiscountEvent()
        extraDiscount += memberDiscount
      }
    }

    if ( store.product?.discount ) {
      extraDiscount += store.product?.discount
    }

    return extraDiscount
  }

  function handleIdentificationDataChange( id: string, value: string, isValid: boolean, helperText: string ): void {
    setIdentificationData( {
      [ id ]: {
        value,
        isValid,
        helperText,
      },
    } )
  }

  function handleValidPostalCode(): void {
    if ( step.current === 'identification' ) {
      if ( store.promotionCoupon.state === 'applied' ) {
        applyCoupon()
      }
      step.proceed( 'identification/address' )
    }
  }

  function handleInvalidPostalCode( invalidPostalCode?: string ): void {
    const commomErrorData = {
      'isValid': false,
      'helperText': '',
      'disabled': false,
      'value': ''
    }

    setIdentificationData( {
      ...store.identification,
      'city': commomErrorData,
      'state': commomErrorData,
      'street': commomErrorData,
      'district': commomErrorData,
      'postalCode': {
        ...commomErrorData,
        'helperText': validationTexts.INVALID_CEP_MESSAGE,
        'value': invalidPostalCode || '',
      },
    } )
  }

  function handlePaymentMethodChange( method: PaymentMethod.Method ): void {
    setPaymentMethod( method )
    step.proceed( `payment/${method}` )
    selectPaymentMethodEvent()
    registerPaymentInfoEvent( 'documentNumber', store.payment.documentNumber.value, store.payment.documentNumber.isValid, method )
  }

  function registerPaymentInfoEvent( id:string, value:string, isValid: boolean, paymentMethod?: PaymentMethod.Method ) {
    const method = paymentMethod ? paymentMethod : store.payment.method
    if ( method === PAYMENT_METHODS.CARD ) {
      const validByDocumentNumber =
        isValid &&
        id === 'documentNumber' &&
        ( paymentMethod || value !== store.payment.documentNumber.value ) &&
        store.payment.cardNumber.isValid
      const validByCardNumber =
        isValid &&
        id === 'cardNumber' &&
        ( paymentMethod || value !== store.payment.cardNumber.value ) &&
        store.payment.documentNumber.isValid
      if ( validByDocumentNumber || validByCardNumber ) {
        addPaymentInfoEvent()
      }
    }
    if ( method === PAYMENT_METHODS.PIX ) {
      if ( isValid && id === 'documentNumber' && ( paymentMethod || value !== store.payment.documentNumber.value ) ) {
        addPaymentInfoEvent()
      }
    }
  }

  async function validateIfCpfAlreadyHasSubscription( id: string, value: string, isValid: boolean, helperText: string ) {
    const isDocumentNumber = id === 'documentNumber' && value.length === 14
    if ( !isDocumentNumber ) {
      return
    }

    if ( store.subscription.isSubscriber === false ) {
      await verifySubscriptionStatusByCpf( value.replace( '.', '' ).replace( '.', '' ).replace( '-', '' ) )
      registerPaymentInfoEvent( id, value, isValid )
    }

    setPaymentData( {
      ...store.payment,
      [ id ]: {
        value,
        isValid,
        helperText,
        'disabled': false,
      }
    } )
  }

  async function handlePaymentDataChange( id: string, value: string, isValid: boolean, helperText: string ): Promise<void> {
    const asyncValid = ( isValid && id !== 'documentNumber' )

    setPaymentData( {
      ...store.payment,
      [ id ]: {
        value,
        'isValid': asyncValid,
        helperText,
        'disabled': false,
      }
    } )

    if ( isValid && id === 'cardNumber' ) {
      registerPaymentInfoEvent( id, value, isValid )
    }

    await validateIfCpfAlreadyHasSubscription( id, value, isValid, helperText )
  }

  function handleIdentificationSummaryOpen(): void {
    setCollapseIdentificationForm( false )
    step.proceed( 'identification/address' )
  }

  function handlePromotionCouponChange( code: string ): void {
    changeCouponCode( code )
  }

  function getTotalDiscount(): number {
    const totalDiscount = store.promotionCoupon.appliedValue.price + paymentMethodDiscount + getExtraDiscount()
    return totalDiscount
  }

  async function handleProceed(): Promise<void> {
    if ( step.concluded === 'payment' ) {
      try {
        setIsPaying( true )
        await verifyMaintenance()

        if ( store.payment.method === PAYMENT_METHODS.PIX ) {
          pixPaymentAttemptEvent()
        } else {
          purchaseSubmitEvent()
        }

        const payload: CardPayload = {
          'identification': Object.keys( store.identification ).reduce( ( result, key ) => ( {
            ...result,
            [ key ]: ( store.identification as any )[ key ].value,
          } ), {} as any ),
          'payment': Object.keys( store.payment ).reduce( ( result, key ) => ( {
            ...result,
            [ key ]: ( store.payment as any )[ key ].value
          } ), {} as any ),
          'coupon': store.promotionCoupon.code,
          'isRecommendationCoupon': store.promotionCoupon.isRecommendationCoupon,
          'plan': { 'id': store.product?.planId },
          'category': { 'id': store.product?.categoryId },
        }

        payload.identification.ibge = store.identification.ibge

        if ( store.identification.giftAddress.useGiftAddress ) {
          const giftAddress = Object.keys( store.identification.giftAddress ).reduce( ( result, key ) => ( {
            ...result,
            [ key ]: ( store.identification.giftAddress as any )[ key ].value,
          } ), {} as any )
          payload.identification.giftAddress = giftAddress
          payload.identification.giftAddress.ibge = store.identification.giftAddress.ibge
        }
        await purchase( store.payment.method!, payload )

        if ( store.payment.method === PAYMENT_METHODS.PIX ) {
          step.proceed( 'payment/pix/confirmation' )
        } else {
          step.proceed( 'conclusion' )
        }
      } catch ( error: any ) {
        if ( error.code !== 'NONSUBSCRIBER_CUSTOMER' ) {
          setPaymentError( true )
        }
      } finally {
        setMayProceedStep( true )
        setIsPaying( false )
      }
    } else {
      beginPaymentInfoEvent()
      selectPaymentMethodEvent()
      step.proceed()
    }
  }

  function handleSubmit(): void {
    if ( store.step.mayProceedStep ) {
      handleProceed()
    }
  }

  async function handlePixPaymentVerification(): Promise<void> {
    const status = await verifyPixPayment( store.payment.pixData.hash )

    if ( status === 'paid' ) {
      step.proceed( 'conclusion' )
    }
  }

  if ( !store.product ) {
    return null
  }

  if ( step.current === 'conclusion' && verifyAndRedirectToAda() ) {
    return null
  }

  const productDescriptionElement = () => (
    <ProductDescription
      image={ store.product!.productImage }
      title={ store.product!.title }
      description={ store.product!.description }
    />
  )

  const identificationSummaryElement = () => (
    <IdentificationSummary
      customer={{
        'email': store.identification.email.value,
        'name': store.identification.name.value,
      }}
      address={{
        'postalCode': store.identification.postalCode.value,
        'city': store.identification.city.value,
        'complement': store.identification.complement.value,
        'number': store.identification.number.value,
        'state': store.identification.state.value,
        'street': store.identification.street.value,
      }}
      discount={ 0 }
      shippingPrice={ store.shipping.price ?? 0 }
      hideShippingInfo={ store.product?.hideShippingInfo }
      onClick={ handleIdentificationSummaryOpen }
    />
  )

  const identificationGiftAddressElement = () => {
    if ( !product.enableSaleGift || !step.isConcluded( 'identification' ) ) {
      return null
    }

    if ( displayIdentificationGiftAddressSumary ) {
      return ( <IdentificationSummaryGiftAddress onClick={ handleIdentificationSummaryOpen } /> )
    }

    if ( store.identification.giftAddress.useGiftAddress ) {
      return (
        <>
          <ToggleGiftAddress />
          <GiftAddressForm />
        </>
      )
    }

    return ( <ToggleGiftAddress /> )
  }

  const paymentMethodElement = () => (
    <PaymentMethod
      fields={ store.payment }
      selected={ store.payment.method }
      purchaseActionText={ purchaseActionText }
      productPrice={ store.product!.price - store.promotionCoupon.appliedValue.price - paymentMethodDiscount - getExtraDiscount() }
      shippingPrice={ store.shipping.price }
      monthlyPlan={ isMonthlyProduct( Number( store.product!.planId ) ) }
      paymentMethodsDetail={ store.product!.paymentMethodsDetail }
      onChangeMethod={ handlePaymentMethodChange }
      onChangeData={ handlePaymentDataChange }
      onSubmit={ handleSubmit }
    />
  )

  const identificationFormElement = () => (
    <IdentificationForm
      fields={ store.identification }
      hideShippingInfo={ product.hideShippingInfo }
      displayAddress={ step.isConcluded( 'identification' ) }
      isActiveSubscriber={ store.lead.status === 'active' }
      isProductSubscription={ store.product!.isSubscription }
      onValidPostalCode={ handleValidPostalCode }
      onInvalidPostalCode={ handleInvalidPostalCode }
      onChange={ handleIdentificationDataChange }
      onSubmit={ handleSubmit }
    />
  )

  const promotionCouponElement = () => (
    <PromotionCoupon
      { ...store.promotionCoupon }
      onChange={ handlePromotionCouponChange }
      onApply={ applyCoupon }
    />
  )

  const giftSummaryElement = () => {
    if ( !store.product!.gift ) {
      return null
    }
    return (
      <GiftSummary
        gift={ store.product!.gift }
        summaryTitle="Brinde exclusivo"
      />
    )
  }

  const mimoSummaryElement = () => {
    if ( !store.product!.mimo ) {
      return null
    }
    return (
      <GiftSummary
        gift={ store.product!.mimo }
        summaryTitle="Mimo exclusivo"
      />
    )
  }

  const totalSummaryElement = () => (
    <TotalSummary
      productPrice={ store.product!.price }
      discount={ getTotalDiscount() }
      shipping={ store.shipping.price }
      installments={ getInstallments() }
      disabled={ !store.step.mayProceedStep }
      currentStep={ step.current }
      concludedStep={ step.concluded }
      onContinue={ handleProceed }
      showLoader={ store.payment.isPaying }
      isFreeShipping={ store.product!.isFreeShipping! }
      purchaseActionText={ purchaseActionText }
      totalSummaryCtaDescription={ store.product!.totalSummaryCtaDescription }
      hideShippingInfo={ product.hideShippingInfo }
    />
  )

  const conclusionElement = () => (
    <Conclusion
      product={ store.product! }
    />
  )

  const advertisingElement = () => (
    <Advertising />
  )

  const pixConfirmationElement = () => (
    <PixConfirmation
      { ...store.payment.pixData }
      onVerifyPayment={ handlePixPaymentVerification }
    />
  )

  const termsAndConditionsElement = () => (
    <TermsAndConditions
      purchaseActionText={ purchaseActionText }
    />
  )

  const shouldDisplayProductRedirectModal = () => {
    const isActiveSubscriber = (
      store.subscription.isSubscriber !== false &&
      ( store.subscription.status === null || store.subscription.status === 'ATIVO' )
    )

    return !store.product!.availableForNonSubscribers && !isActiveSubscriber
  }

  const shouldDisplayPromotionCouponElement = () => {
    return store.product!.displayPromotionCoupon
  }

  if ( isMobile ) {
    return (
      <S.Container>
        <Header />
        <S.Mobile className="Mobile">
          <div>
            {productDescriptionElement()}
            {giftSummaryElement()}
            {mimoSummaryElement()}
          </div>
          {step.current !== 'conclusion' ? (
            <>
              {step.current !== 'payment/pix/confirmation' ? (
                <div>
                  {displayIdentificationSummary
                    ? <div className="identification-summary">{identificationSummaryElement()}</div>
                    : identificationFormElement()}
                  { identificationGiftAddressElement() }
                  {displayPaymentMethod && paymentMethodElement()}
                  { shouldDisplayPromotionCouponElement() && promotionCouponElement() }
                  {step.isConcluded( 'payment' ) && termsAndConditionsElement()}
                </div>
              ) : (
                pixConfirmationElement()
              )}
              <div>
                {totalSummaryElement()}
              </div>
            </>
          ) : (
            <S.Conclusion>
              {conclusionElement()}
              {advertisingElement()}
            </S.Conclusion>
          )}
        </S.Mobile>
        <PaymentErrorModal
          shouldDisplay={ store.payment.error }
          errorReason={ store.payment.errorReason }
        />
        <SubscriptionErrorModal
          resetSteps={ step.resetSteps }
          subscriptionStatus={ store.subscription.status }
          documentNumber={ store.payment.documentNumber.value }
          shouldDisplay={ Boolean( store.subscription.isSubscriber ) && store.product.isSubscription }
          shouldDisplayEmail={ store.payment.documentNumber.value !== '' }
          subscriptionEmail={ store.subscription.email }
        />
        <ProductRedirectModal
          resetSteps={ step.resetSteps }
          shouldDisplay={ shouldDisplayProductRedirectModal() }
        />
      </S.Container>
    )
  } else {
    return (
      <S.Container>
        <Header />
        <S.Desktop className="Desktop">
          <div>
            {step.current !== 'conclusion' ? (
              step.current !== 'payment/pix/confirmation' ? (
                <div>
                  <div className={ `identification-${displayIdentificationSummary ? 'summary' : 'form'}` }>
                    {displayIdentificationSummary ? identificationSummaryElement() : identificationFormElement()}
                    { identificationGiftAddressElement() }
                  </div>
                  {displayPaymentMethod && paymentMethodElement()}
                  {step.isConcluded( 'payment' ) && termsAndConditionsElement()}
                  {!step.isConcluded( 'payment' ) && (
                    <S.ProcedureCTA
                      className="procedure-cta"
                      disabled={ !store.step.mayProceedStep }
                      onClick={ handleProceed }
                    >
                      <Typography
                        color="WHITE"
                        variant="button-medium-bold"
                      >
                        Continuar
                      </Typography>
                    </S.ProcedureCTA>
                  )}
                </div>
              ) : (
                pixConfirmationElement()
              )
            ) : (
              <>
                <div>{conclusionElement()}</div>
                <div>{advertisingElement()}</div>
              </>
            )}
          </div>
          <div>
            <div>
              {productDescriptionElement()}
            </div>
            {giftSummaryElement()}
            {mimoSummaryElement()}
            {step.current !== 'conclusion' && (
              <>
                <div>
                  {totalSummaryElement()}
                </div>
                {shouldDisplayPromotionCouponElement() && (
                  <div>
                    {promotionCouponElement()}
                  </div>
                )}
              </>
            )}
          </div>
        </S.Desktop>
        <PaymentErrorModal
          shouldDisplay={ store.payment.error }
          errorReason={ store.payment.errorReason }
        />
        <SubscriptionErrorModal
          resetSteps={ step.resetSteps }
          subscriptionStatus={ store.subscription.status }
          documentNumber={ store.payment.documentNumber.value }
          shouldDisplay={ Boolean( store.subscription.isSubscriber ) && store.product.isSubscription }
          shouldDisplayEmail={ store.payment.documentNumber.value !== '' }
          subscriptionEmail={ store.subscription.email }
        />
        <ProductRedirectModal
          resetSteps={ step.resetSteps }
          shouldDisplay={ shouldDisplayProductRedirectModal() }
        />
      </S.Container>
    )
  }
}
namespace Controller {
  export interface Props {
    product: Product
    promotionCouponCode?: string
    verifyMaintenance: Function
  }

  interface InputVariables {
    value: string
    isValid: boolean
  }

  export interface FormState {
    name: InputVariables
    email: InputVariables
    postalCode: InputVariables
    city: InputVariables
    state: InputVariables
    street: InputVariables
    number: InputVariables
    complement: InputVariables
    district: InputVariables
  }
}

export default ControllerView
