/* eslint-disable max-statements */
import { PAYMENT_METHODS } from '@one-checkout/constants'
import { getTrailDiscountCoupon, getClubPromotionCoupon, getRecommendationCoupon, PromotionCoupon, RecommendationCoupon, getExperienceCoupon } from '@one-checkout/services/coupon.service'
import { setEventData, addCouponErrorEvent, addCouponEvent, addCouponRequestEvent, addCouponSubmitEvent } from '@one-checkout/actions/event.action'
import store from '@one-checkout/store'
import promotionCouponSlice from '@one-checkout/store/promotionCoupon.slice'
import shippingSlice from '@one-checkout/store/shipping.slice'
import { isYearlyProduct } from '@one-checkout/services/product.service'

type FulfilledCouponPromise = PromiseFulfilledResult<PromotionCoupon | RecommendationCoupon>
type CouponValues = { percent: number, price: number }

function getPromotionCouponValues( { promotionInfo }: PromotionCoupon, shipping: shippingSlice.State, productPrice: number, planId: number ): CouponValues {
  const values = promotionInfo.map( info => {
    const isShipping = info.target.startsWith( 'FRETE' )
    let targetPrice = isShipping ? shipping.price ?? 0 : productPrice

    if ( info.target.endsWith( 'KIT' ) ) {
      if ( isYearlyProduct( planId ) ) {
        targetPrice /= 12
      }
    }

    const price = info.isPercent ? targetPrice * ( info.price / 100 ) : info.price
    const percent = info.isPercent ? info.price : 0

    return { price, percent }
  } )

  const finalPrice = values.reduce( ( sum, { price } ) => sum + price, 0 )
  const finalPercent = values.reduce( ( sum, { price, percent } ) => {
    if ( percent === 0 ) {
      return sum
    }

    const valueWeight = price / finalPrice
    sum += percent * valueWeight

    return sum
  }, 0 )

  return {
    'price': finalPrice,
    'percent': Number( finalPercent.toFixed( 1 ) ),
  }
}

export async function applyCoupon(): Promise<void> {
  const { product, identification, payment, promotionCoupon, shipping } = store.getState()
  const { code } = promotionCoupon
  const { 'price': productPrice, planId, isSubscription, type } = product!
  const email = identification.email.value
  const paymentMethod = payment.method || PAYMENT_METHODS.CARD
  setEventData( { 'promotionCoupon': code } )
  addCouponSubmitEvent()
  try {
    addCouponRequestEvent()
    let couponPromises = []

    if ( type === 'experience' ) {
      couponPromises.push( getExperienceCoupon( code, planId!, paymentMethod ) )
    } else {
      couponPromises = [
        getRecommendationCoupon( code, planId! ),
        isSubscription ? getClubPromotionCoupon( code, planId!, paymentMethod ) : getTrailDiscountCoupon( code, email )
      ]
    }

    const couponResponses = await Promise.allSettled( couponPromises )
    const { 'value': coupon } = <FulfilledCouponPromise>couponResponses.find( response => response.status === 'fulfilled' && response.value )
    const { couponDescription, promotionInfo } = coupon

    const isRecommendationCoupon = 'isRecommendation' in promotionInfo[ 0 ]! && promotionInfo[ 0 ].isRecommendation
    if ( isRecommendationCoupon ) {
      setPromotionCouponData( {
        'state': 'applied',
        code,
        couponDescription,
        isRecommendationCoupon,
        'appliedValue': {
          'percent': 0,
          'price': promotionInfo[ 0 ]!.price,
        },
      } )
      addCouponEvent( promotionInfo[ 0 ]!.price )
      return
    }

    const otherThanShippingPromotionInfo = ( promotionInfo as unknown as PromotionCoupon.PromotionInfo[] ).find( ( { target } ) => !target.startsWith( 'FRETE' ) )
    const promotionCouponValues = getPromotionCouponValues( <PromotionCoupon>coupon, shipping, productPrice, planId! )

    setPromotionCouponData( {
      'benefitType': otherThanShippingPromotionInfo?.target ?? ( promotionInfo[ 0 ] as { target: PromotionCoupon.Target } ).target,
      'state': 'applied',
      code,
      couponDescription,
      'isRecommendationCoupon': false,
      'appliedValue': promotionCouponValues,
    } )
    addCouponEvent( promotionCouponValues.price )
    return
  } catch ( error ) {
    setPromotionCouponData( {
      'state': 'invalid',
      code,
      'couponDescription': '',
      'isRecommendationCoupon': false,
      'appliedValue': {
        'price': 0,
        'percent': 0,
      },
    } )
    addCouponErrorEvent( ( error as Error ).message )
  }
}

export function changeCouponCode( code: string ): void {
  setPromotionCouponData( {
    'state': 'unapplied',
    code,
    'couponDescription': '',
    'isRecommendationCoupon': false,
    'appliedValue': {
      'price': 0,
      'percent': 0,
    },
  } )
}

function setPromotionCouponData( discountData: promotionCouponSlice.SetPromotionCouponDataPayload ) {
  store.dispatch( promotionCouponSlice.actions.setPromotionCouponData( discountData ) )
}
