/* eslint-disable camelcase */
import { backendClient } from './client'

export interface RecommendationCoupon {
  promotionInfo: RecommendationCoupon.PromotionInfo[]
  couponDescription: string
}

export interface PromotionCoupon {
  couponDescription: string
  promotionInfo: PromotionCoupon.PromotionInfo[]
}

export namespace RecommendationCoupon {
  export type PromotionInfo = {
    price: number
    isRecommendation: boolean
  }
}

export namespace PromotionCoupon {
  export type PromotionInfo = {
    price: number
    isPercent: boolean
    target: PromotionCoupon.Target
  }

  export type Target =
    | 'TOTAL'
    | 'KIT'
    | 'FRETE_1_KIT'
    | 'FRETE_TODOS'
    | 'VARIABLE_ACTION'
}

export async function getTrailDiscountCoupon( code: string, email?: string ): Promise<PromotionCoupon> {
  const { data } = await backendClient.get<PromotionCouponResponse>( '/v2/codigo-promocao-venda-base', {
    'params': {
      'tipo': 'TRILHAS',
      'codigo': code,
      email,
    }
  } )
  // NOTE: Trilhas só tem cupom sobre o total até então. Forçando typecast
  const { valor, percentual } = data[ code ]!.beneficios[ 0 ] as PromotionCouponResponse.PriceBenefit
  const { 'descricao_personalizada': couponDescription } = data[ code ]!

  return {
    couponDescription,
    'promotionInfo': [
      {
        'price': valor,
        'isPercent': percentual,
        'target': 'TOTAL',
      }
    ]
  }
}

export async function getRecommendationCoupon( code: string, planId: number ): Promise<RecommendationCoupon | null> {
  const sanitizedCode = code.toUpperCase()
  try {
    const { data } = await backendClient.get<RecommendationCouponResponse>( `/v2/codigo-indicacao/${sanitizedCode}/${planId}` )

    return {
      'promotionInfo': [
        {
          'isRecommendation': true,
          'price': data.desconto
        }
      ],
      'couponDescription': data.descricao
    }
  } catch {
    return null
  }
}

export function buildPromotionInfo( benefit: PromotionCouponResponse.Benefit ): PromotionCoupon.PromotionInfo {
  let target: PromotionCoupon.Target
  let price = 0
  let isPercent = false

  if ( 'produto' in benefit ) {
    target = 'VARIABLE_ACTION'
  } else {
    target = benefit.tipo_desconto.codigo as PromotionCoupon.Target
    price = benefit.valor
    isPercent = benefit.percentual
  }

  return {
    price,
    isPercent,
    target,
  }
}

export async function getClubPromotionCoupon( code: string, planId: number, paymentMethod: 'pix' | 'card' ): Promise<PromotionCoupon | null> {
  code = code.toUpperCase()
  try {
    const { data } = await backendClient.get<PromotionCouponResponse>( '/v2/codigo-promocao', {
      'params': {
        'tipo': 'CRIACAO_ASSINATURA',
        'codigo': code,
        'forma_pagamento': paymentMethod === 'pix' ? 'P' : 'C',
        'plano_id': planId,
      }
    } )

    const discountCoupon = data[ code ]!
    const benefits = discountCoupon.beneficios as [PromotionCouponResponse.Benefit]

    const productDescription = benefits
      .filter( benefit => 'produto' in benefit )
      .map( ( benefit ) => ( benefit as PromotionCouponResponse.VariableActionBenefit ).produto.exibicao )
      .join( ', ' )

    const couponDescription = discountCoupon.descricao_personalizada || productDescription

    return {
      couponDescription,
      'promotionInfo': benefits.map( buildPromotionInfo )
    }
  } catch {
    return null
  }
}

export async function getExperienceCoupon( code: string, planId: number, paymentMethod: 'pix' | 'card' ): Promise<PromotionCoupon | null> {
  try {
    const { data } = await backendClient.get<PromotionCouponResponse>( '/v2/codigo-promocao', {
      'params': {
        'tipo': 'EXPERIMENTE',
        'codigo': code,
        'forma_pagamento': paymentMethod === 'pix' ? 'P' : 'C',
        'plano_id': planId,
      }
    } )

    const discountCoupon = data[ code ]!
    const priceBenefit = discountCoupon.beneficios.find( ( benefitItem ) => ( benefitItem as PromotionCouponResponse.ExperienceBenefit ).tipo === 'Desconto' ) as PromotionCouponResponse.PriceBenefit

    const couponDescription = discountCoupon.descricao_personalizada

    const target = priceBenefit.tipo_desconto.codigo as PromotionCoupon.Target
    const price = priceBenefit.valor
    const isPercent = priceBenefit.percentual

    return {
      couponDescription,
      'promotionInfo': [
        {
          price,
          isPercent,
          target,
        }
      ],
    }
  } catch {
    return null
  }
}

interface RecommendationCouponResponse {
  codigo: string,
  desconto: number,
  descricao: string,
}

interface PromotionCouponResponse {
  [code: string]: {
    id_promocao: number
    descricao_personalizada: string
    beneficios: Array<PromotionCouponResponse.PriceBenefit | PromotionCouponResponse.VariableActionBenefit | PromotionCouponResponse.ExperienceBenefit>
  }
}
namespace PromotionCouponResponse {
  export interface PriceBenefit {
    tipo: string
    valor: number
    percentual: boolean
    tipo_desconto: {
      codigo: string
      display: string
    }
  }

  export interface VariableActionBenefit {
    produto: {
      descricao: string
      exibicao: string
      foto: string
      id: number
      peso: number
      preco: number
    }
  }

  export interface ExperienceBenefit {
    kit: string
    tipo: string
  }

  export type Benefit = PriceBenefit | VariableActionBenefit
}
