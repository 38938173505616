import { ReactComponent as AmexFlag } from '../../../assets/creditCardFlags/amex.svg'
import { Container } from './styles'
import { ReactComponent as EloFlag } from '../../../assets/creditCardFlags/elo.svg'
import { ReactComponent as HipercardFlag } from '../../../assets/creditCardFlags/hipercard.svg'
import { ReactComponent as MasterCardFlag } from '../../../assets/creditCardFlags/mastercard.svg'
import PropTypes from 'prop-types'
import React from 'react'
import { ReactComponent as VisaFlag } from '../../../assets/creditCardFlags/visa.svg'


const CardFlag = ( { flag } ) => {
  const flagList = {
    'american-express':
  <AmexFlag
    aria-label="Amex img"
    key={ 1 }
    role="img"
  />,
    'elo':
  <EloFlag
    aria-label="Amex img"
    key={ 2 }
    role="img"
  />,
    'hipercard':
  <HipercardFlag
    aria-label="Amex img"
    key={ 3 }
    role="img"
  />,
    'mastercard':
  <MasterCardFlag
    aria-label="Amex img"
    key={ 4 }
    role="img"
  />,
    'visa':
  <VisaFlag
    aria-label="Amex img"
    key={ 5 }
    role="img"
  />
  }

  return (
    <Container>
      {flag in flagList && ( flagList[ flag ] )}
    </Container>
  )
}

CardFlag.propTypes = {
  'flag': PropTypes.string.isRequired,
}

export { CardFlag }


