export const MOBILE_THRESHOLD = 1024
export const DESKTOP_BREAKPOINT = MOBILE_THRESHOLD + 1


const PIX = 'pix' as const
const CARD = 'card' as const
export const PAYMENT_METHODS = {
  PIX,
  CARD,
}
